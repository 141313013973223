.pac-container {
  border: 2px solid #efefef !important;
  border-top: 2px solid #efefef !important;
  border-radius: 10px;
  transform: translateY(9px);
}

.pac-item {
  font-size: 14px !important;
}
