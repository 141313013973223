html,
body,
#root {
  max-width: 100vw;
  display: flex;
  flex: 1;
  flex-grow: 1;
  overflow-x: hidden;
  flex-direction: column;
}

.ant-tabs {
  max-height: calc(100vh - 90px);
}

.ant-tabs > .ant-tabs-content {
  max-height: calc(100vh - 161px);
}

* {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: unset !important;
}
